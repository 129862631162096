<template>
  <div class="cl-pagination">
    <ul class="cl-pagination__holder">
      <li class="cl-pagination__item" v-for="item in items" :key="item.id">
        <button
          class="cl-pagination__link"
          v-bind:class="getLinkClass(item.id)"
          v-on:click="$emit('pagination-change', item.id)"
        >
          {{ item.number }}
        </button>
        <!--cl-pagination__link_state_active-->
      </li>

      <li class="cl-pagination__item" v-if="!isLast">
        <span class="cl-pagination__separator">...</span>
      </li>

      <li class="cl-pagination__item" v-if="!isLast">
        <button
          class="cl-pagination__link"
          v-bind:class="getLinkClass(paginationTotal - 1)"
          v-on:click="$emit('pagination-change', paginationTotal - 1)"
        >
          {{ paginationTotal }}
        </button>
        <!--cl-pagination__link_state_active-->
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "Pagination",
  props: {
    total: Number,
    current: Number,
    displayed: Number,
  },
  data: function () {
    return {};
  },
  mounted: function () {},
  computed: {
    paginationCurrent: function () {
      let number = this.current;

      if (this.current > this.paginationTotal) {
        number = this.paginationTotal;
      }

      return number;
    },
    paginationTotal: function () {
      let total = 0;
      total = this.total / this.displayed;

      return Math.ceil(total);
    },
    items: function () {
      let pages = [];

      pages.push({
        id: this.paginationCurrent,
        number: this.paginationCurrent + 1,
      });

      for (let i = 1; i <= 4; i++) {
        let currentMinus = this.paginationCurrent - i,
          currentPlus = this.paginationCurrent + i;

        if (currentMinus >= 0 && currentPlus !== this.currentMinus) {
          pages.unshift({
            id: currentMinus,
            number: currentMinus + 1,
          });
        }

        if (
          currentPlus < this.paginationTotal - 1 &&
          currentPlus !== this.paginationCurrent
        ) {
          pages.push({
            id: currentPlus,
            number: currentPlus + 1,
          });
        }
      }

      return pages;
    },
    isLast: function () {
      return this.paginationCurrent === this.paginationTotal - 1;
    },
  },
  methods: {
    getLinkClass(id) {
      return {
        "cl-pagination__link_state_active": id === this.paginationCurrent,
      };
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus">
/* begin .cl-pagination */
.cl-pagination {
  overflow: hidden;
}

.cl-pagination__holder {
  display: flex;
  margin: 0 -5px;
  padding: 0;
  list-style: none;
}

.cl-pagination__item {
  padding: 0 5px;
}

.cl-pagination__link, .cl-pagination__separator {
  display: block;
  width: 40px;
  height: 40px;
  opacity: 1;
  color: #0B0B0B;
  font-size: 16px;
  font-weight: 500;
  line-height: 40px;
  text-align: center;
  text-decoration: none;
  border: none;
  background: #CACACA;
  transition: opacity 0.4s ease;
  cursor: pointer;
}

.cl-pagination__link_state_active {
  color: #fff;
  background: #171717;
}

.cl-pagination__link:hover {
  opacity: 0.6;
}

/* end .cl-pagination */
</style>
