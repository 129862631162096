<template>
  <div class="cl-grid__inner cl-image-page">
    <div
      class="cl-image-page__wrapper"
      v-bind:style="{ backgroundImage: 'url(' + imagePath + ')' }"
    >
      <img :src="imagePath" alt="Page" class="cl-image-page__image" />
    </div>
  </div>
</template>

<script>
export default {
  name: "IllustratedPage",
  props: {
    imagePath: String,
  },
  data: function () {
    return {};
  },
  mounted: function () {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus">
/* begin .cl-page */
.cl-image-page {
  padding: 5px;
  background: #fff;
}

.cl-image-page__wrapper {
  position: relative;
  padding: 0 0 136%;
  background-color: #e5e5e5;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

.cl-image-page__image {
  position: absolute;
  top: 50%;
  left: 50%;
  display: none;
  width: auto;
  max-width: 100%;
  min-width: 100%;
  height: auto;
  transform: translate(-50%, -50%);
}

/* end .cl-page */
</style>
