<template>
  <div>
    <div
      class="cl-viewer"
      v-bind:class="{
        'cl-viewer_zoom-mode': isActiveMode('zoom'),
      }"
    >
      <div class="cl-viewer__search" style="display: none">
        <SearchForm key="" />
      </div>
      <div class="cl-viewer__meta">
        <div class="cl-meta">
          <div class="cl-meta__line">{{ book.author }}</div>
          <div class="cl-meta__line">
            <a href="#" class="cl-meta__link">{{ book.meta }}</a>
          </div>
        </div>
      </div>
      <div class="cl-viewer__header">
        <div class="cl-viewer__title">{{ book.title }}</div>
      </div>
      <div class="cl-viewer__holder cl-viewer__main-panel">
        <div class="cl-viewer__main">
          <div class="cl-viewer__zoom-panel">
            <div class="cl-grid">
              <div class="cl-grid__holder">
                <div
                  class="cl-grid__item"
                  v-bind:class="{
                    'cl-grid__item_size_1': isActiveMode('page'),
                    'cl-grid__item_size_2': isActiveMode('book'),
                    'cl-grid__item_size_3': isActiveMode('grid'),
                  }"
                  v-for="page in activePages"
                  :key="page.id"
                >
                  <Page :text="page.text" v-if="!isImage" />
                  <IllustratedPage :imagePath="page.image" v-if="isImage" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="cl-viewer__controls">
          <ul class="cl-controls">
            <!-- Увеличить -->
            <li class="cl-controls__item" v-if="!isActiveMode('zoom')">
              <button type="button" class="cl-control" v-on:click="setZoomMode">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  class="cl-control__icon"
                >
                  <path
                    d="M15.2213 7.01326V9.02242H9.00252V15.7674H6.80202V9.02242H0.535364V7.01326H6.80202V0.26824H9.00252V7.01326H15.2213Z"
                  />
                </svg>
              </button>

              <div class="cl-controls__tooltip">
                <div class="cl-tooltip">Режим увеличить</div>
              </div>
            </li>

            <!-- Уменьшить -->
            <li class="cl-controls__item" v-if="isActiveMode('zoom')">
              <button
                type="button"
                class="cl-control"
                v-on:click="unsetZoomMode"
              >
                <svg
                  width="14"
                  height="4"
                  viewBox="0 0 14 4"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  class="cl-control__icon"
                >
                  <path
                    d="M0.205083 3.08857V0.888067H13.9822V3.08857H0.205083Z"
                    fill="black"
                  />
                </svg>
              </button>
              <div class="cl-controls__tooltip">
                <div class="cl-tooltip">Режим уменьшить</div>
              </div>
            </li>

            <!-- Книжный вид -->
            <li class="cl-controls__item">
              <button
                type="button"
                class="cl-control"
                :disabled="isZoom"
                v-bind:class="{
                  'cl-control_state_active': isActiveMode('book'),
                }"
                v-on:click="displayedPages = 2"
              >
                <svg
                  width="24"
                  height="20"
                  viewBox="0 0 24 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  class="cl-control__icon"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M22.5625 0.25H13.625L13.0562 0.49375L12 1.53375L10.9437 0.49375L10.375 0.25H1.4375L0.625 1.0625V17.3125L1.4375 18.125H10.0337L11.4313 19.5063H12.5688L13.9663 18.125H22.5625L23.375 17.3125V1.0625L22.5625 0.25ZM11.1875 17.02L10.895 16.7437L10.375 16.5H2.25V1.875H10.0337L11.2362 3.0775L11.1875 17.02ZM21.75 16.5H13.625L13.0562 16.7437L12.8288 16.955V3.0125L13.9663 1.875H21.75V16.5ZM8.75 5.125H3.875V6.75H8.75V5.125ZM8.75 11.625H3.875V13.25H8.75V11.625ZM3.875 8.375H8.75V10H3.875V8.375ZM20.125 5.125H15.25V6.75H20.125V5.125ZM15.25 8.375H20.125V10H15.25V8.375ZM15.25 11.625H20.125V13.25H15.25V11.625Z"
                  />
                </svg>
              </button>
              <div class="cl-controls__tooltip">
                <div class="cl-tooltip">Режим книга</div>
              </div>
            </li>

            <!-- Вид картинками -->
            <li class="cl-controls__item" v-if="isActiveMode('text')">
              <button
                type="button"
                class="cl-control"
                :disabled="isZoom"
                v-bind:class="{
                  'cl-control_state_active': isActiveMode('image'),
                }"
                v-on:click="isImage = !isImage"
              >
                <svg
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  enable-background="new 0 0 512 512"
                  class="cl-control__icon"
                >
                  <path
                    d="M480.6,11H31.4C20.1,11,11,20.1,11,31.4v449.2c0,11.3,9.1,20.4,20.4,20.4h449.2c11.3,0,20.4-9.1,20.4-20.4V31.4     C501,20.1,491.9,11,480.6,11z M460.2,51.8v133.8c-67.3,8.2-119.4,31.2-159.7,60.9C181.2,235.6,96.9,302,51.8,350.8V51.8H460.2z      M51.8,416.1c15-22.2,87-119,203.8-129.1c-58,63.7-79.4,139.1-86.5,173.1H51.8V416.1z M210.5,460.2     c12.7-58.1,63.5-208.3,249.7-233.4v233.4H210.5z"
                  />
                  <path
                    d="m153.8,213.4c35.2,0 63.9-28.7 63.9-63.9 0-35.2-28.6-63.9-63.9-63.9-35.2,0-63.9,28.7-63.9,63.9 0.1,35.2 28.7,63.9 63.9,63.9zm0-86.9c12.7,0 23,10.3 23,23 0,12.7-10.3,23-23,23-12.7,0-23-10.3-23-23 0-12.7 10.3-23 23-23z"
                  />
                </svg>
              </button>
              <div class="cl-controls__tooltip">
                <div class="cl-tooltip">Режим картинка</div>
              </div>
            </li>

            <!-- Текстовый вид -->
            <li class="cl-controls__item" v-if="isActiveMode('image')">
              <button
                type="button"
                class="cl-control"
                :disabled="isZoom"
                v-bind:class="{
                  'cl-control_state_active': isActiveMode('text'),
                }"
                v-on:click="isImage = !isImage"
              >
                <svg
                  width="160"
                  height="140"
                  viewBox="0 0 160 140"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  class="cl-control__icon"
                >
                  <rect y="120" width="120" height="20" />
                  <rect y="80" width="160" height="20" />
                  <rect width="160" height="20" />
                  <rect y="40" width="120" height="20" />
                </svg>
              </button>
              <div class="cl-controls__tooltip">
                <div class="cl-tooltip">Режим текст</div>
              </div>
            </li>

            <!-- Вид сеткой -->
            <li class="cl-controls__item" v-if="!isActiveMode('grid')">
              <button
                type="button"
                class="cl-control"
                :disabled="isZoom"
                v-bind:class="{
                  'cl-control_state_active': isActiveMode('grid'),
                }"
                v-on:click="displayedPages = 6"
              >
                <svg
                  width="18"
                  height="19"
                  viewBox="0 0 18 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  class="cl-control__icon"
                >
                  <rect width="4" height="8" />
                  <rect y="11" width="4" height="8" />
                  <rect x="7" width="4" height="8" />
                  <rect x="7" y="11" width="4" height="8" />
                  <rect x="14" width="4" height="8" />
                  <rect x="14" y="11" width="4" height="8" />
                </svg>
              </button>
              <div class="cl-controls__tooltip">
                <div class="cl-tooltip">Вид сетка</div>
              </div>
            </li>

            <!-- Вид одной страницей -->
            <li class="cl-controls__item" v-if="isActiveMode('grid')">
              <button
                type="button"
                class="cl-control"
                :disabled="isZoom"
                v-bind:class="{
                  'cl-control_state_active': isActiveMode('page'),
                }"
                v-on:click="displayedPages = 1"
              >
                <svg
                  width="10"
                  height="10"
                  viewBox="0 0 10 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  class="cl-control__icon"
                >
                  <rect width="10" height="10" fill="black" />
                </svg>
              </button>
              <div class="cl-controls__tooltip">
                <div class="cl-tooltip">Режим по одному</div>
              </div>
            </li>

            <!-- Оглавление -->
            <li class="cl-controls__item" style="display: none">
              <button type="button" class="cl-control" :disabled="isZoom">
                <svg
                  width="14"
                  height="15"
                  viewBox="0 0 14 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  class="cl-control__icon"
                >
                  <path
                    d="M11.3085 15L10.245 11.9786H3.77674L2.71318 15H0L5.51318 0H8.50853L14 15H11.3085ZM4.60155 9.66429H9.42016L7.01085 2.89286L4.60155 9.66429Z"
                  />
                </svg>
              </button>
              <div class="cl-controls__tooltip">
                <div class="cl-tooltip">Режим содержание</div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="cl-viewer__footer">
        <div class="cl-viewer__pagination">
          <Pagination
            :total="book.pages.length"
            :current="currentPage"
            :displayed="displayedPages"
            v-on:pagination-change="currentPage = $event"
          />
        </div>
        <div class="cl-viewer__go-to-page">
          <form v-on:submit.prevent="goToPage" class="cl-go-to-page">
            <input
              type="number"
              placeholder="№ страницы"
              class="cl-go-to-page__input"
              name="pageNumber"
            />
            <button type="submit" class="cl-go-to-page__submit">
              <svg
                width="20"
                height="10"
                viewBox="0 0 20 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="cl-go-to-page__icon"
              >
                <path
                  d="M0.794844 5.07997V1.53998C0.794844 1.05998 1.09484 0.660001 1.59484 0.660001C2.09484 0.660001 2.39484 1.05998 2.39484 1.53998V4.19999H16.0549L14.0148 2.17997C13.6748 1.83997 13.6949 1.28001 14.0549 0.920011C14.2349 0.74001 14.4748 0.660001 14.7148 0.660001C14.9348 0.660001 15.1749 0.74001 15.3549 0.920011L18.9549 4.46C19.1149 4.62 19.2148 4.85997 19.2148 5.07997C19.2148 5.31997 19.1149 5.53999 18.9549 5.69999L15.3748 9.23999C15.0348 9.57999 14.4548 9.57999 14.1148 9.23999C13.7748 8.89999 13.7748 8.33997 14.1348 7.97997L16.1948 5.96H1.75485C1.51485 5.96 1.25484 5.85999 1.09484 5.69999C0.894844 5.53999 0.794844 5.29997 0.794844 5.07997Z"
                  fill="#EDEDED"
                />
              </svg>
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SearchForm from "./components/SearchForm.vue";
import Pagination from "./components/Pagination.vue";
import Page from "./components/Page.vue";
import IllustratedPage from "./components/IllustratedPage.vue";
import axios from "axios";

export default {
  name: "App",
  components: {
    SearchForm,
    Pagination,
    Page,
    IllustratedPage,
  },
  data: function () {
    return {
      book: {
        title: null,
        author: null,
        pages: [],
        url: null,
      },
      currentPage: 0,
      displayedPages: 2,
      viewType: "page",
      loading: true,
      errored: false,
      isZoom: false,
      isImage: false,
    };
  },
  beforeMount: function () {
    if (typeof window.bookUrl !== "undefined") {
      this.book.url = window.bookUrl;
    }
  },
  mounted: function () {
    if (this.book.url) {
      axios
        .get(this.book.url)
        .then((response) => {
          this.book = response.data;
        })
        .catch((error) => {
          console.log(error);
          this.errored = true;
        })
        .finally(() => (this.loading = false));
    } else {
      this.$el.innerHTML = "Произошла ошибка загрузки";
      console.log("Проверьте урл источника");
    }
  },
  computed: {
    activePages: function () {
      let startPosition = this.currentPage * this.displayedPages,
        count = this.displayedPages;

      return this.book.pages.slice(startPosition, startPosition + count);
    },
    paginationTotal: function () {
      let total = 0;
      total = this.book.pages.length / this.displayedPages;

      return Math.ceil(total) - 1;
    },
  },
  methods: {
    goToPage(e) {
      let pageNumber = parseInt(e.target.elements.pageNumber.value) - 1;

      if (pageNumber > this.paginationTotal) {
        pageNumber = this.paginationTotal;
      }

      if (isNaN(pageNumber)) {
        pageNumber = 0;
      }

      this.currentPage = pageNumber;
    },
    isActiveMode(type) {
      let result = false;

      if (type === "book" && this.displayedPages === 2) {
        result = true;
      }

      if (type === "grid" && this.displayedPages === 6) {
        result = true;
      }

      if (type === "image" && this.isImage) {
        result = true;
      }

      if (type === "text" && !this.isImage) {
        result = true;
      }

      if (type === "zoom" && this.isZoom) {
        result = true;
      }

      return result;
    },
    setZoomMode() {
      this.isZoom = true;
      this.isImage = true;
      this.displayedPages = 1;
    },
    unsetZoomMode() {
      this.isZoom = false;
    },
  },
};
</script>

<style lang="stylus">
#app {
  width: 100%;
  margin: 0 auto;
}

/* begin .cl-viewer */
.cl-viewer, .cl-viewer * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.cl-viewer {
  padding: 33px 80px 20px;
  // overflow: hidden;
  font-family: 'Gilroy', Arial, sans-serif;
  font-size: 16px;
  line-height: 1.6;
}

.cl-viewer__search {
  padding: 0 20px 0 180px;
}

.cl-viewer__holder {
  position: relative;
  width: 100%;
  height: 100%;
}

.cl-viewer__panel {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  font-size: 0;
  background: #fff;
  box-shadow: 0 3px 12px #a1a1a1;
}

.cl-viewer__main {
  min-width: 100%;
  min-height: 100%;
  overflow: auto;
}

.cl-viewer_zoom-mode .cl-viewer__main {
  max-width: 100%;
  max-height: 90vh;
  overflow: auto;
}

.cl-viewer__main-panel {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
}

.cl-viewer_zoom-mode .cl-viewer__main-panel {
  max-width: 100%;
}

.cl-viewer_zoom-mode .cl-viewer__zoom-panel {
  // width: 200%;
  // transform: scale(2);
}

.cl-viewer__meta {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  padding: 56px 0 6px;
}

.cl-viewer__header {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto 30px;
}

.cl-viewer__title {
  font-size: 28px;
  font-weight: 700;
}

.cl-viewer__footer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  padding: 45px 0 0;
}

.cl-viewer__controls {
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px 15px 0 0;
  transform: translate(80px, 0);
}

/* end .cl-viewer */
/* begin .cl-meta */
.cl-meta {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.6;
}

.cl-meta__link {
  color: #1A8CF5;
  font-weight: 500;
  text-decoration: none;
}

/* end .cl-meta */
/* begin .cl-grid */
.cl-grid {
  overflow: hidden;
}

.cl-grid__holder {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px;
}

.cl-grid__item {
  flex-basis: 100%;
  width: 100%;
  padding: 5px;
}

.cl-grid__item_size_1 {
  flex-basis: 100%;
  width: 100%;
  font-size: 16px;
}

.cl-grid__item_size_2 {
  flex-basis: 50%;
  width: 50%;
  font-size: 12px;
}

.cl-grid__item_size_3 {
  flex-basis: 33.333%;
  width: 33.333%;
  font-size: 10px;
}

.cl-grid__inner {
  height: 100%;
}

/* end .cl-grid */
/* begin .cl-go-to-page */
.cl-go-to-page {
  position: relative;
  padding: 0 40px 0 0;
  border: 1px solid #D3D3D3;
}

.cl-go-to-page__input {
  padding: 0 10px;
  height: 40px;
  color: #000;
  font-size: 16px;
  font-weight: 500;
  line-height: 40px;
  background: transparent;
  border: none;
  border-right: 1px solid #D3D3D3;
  outline: none;
}

.cl-go-to-page__submit {
  position: absolute;
  top: 1px;
  right: 1px;
  display: block;
  width: 38px;
  height: 38px;
  opacity: 1;
  background: #171717;
  border: none;
  outline: none;
  cursor: pointer;
  transition: opacity 0.4s ease;
}

.cl-go-to-page__submit:hover {
  opacity: 0.6;
}

.cl-go-to-page__icon {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 18px;
  height: 9px;
  transform: translate(-50%, -50%);
}

/* end .cl-go-to-page */
/* begin .cl-controls */
.cl-controls {
  display: block;
  margin: 0;
  padding: 0;
  list-style: none;
}

.cl-controls__item {
  position: relative;
  margin: 0 0 20px;
}

.cl-controls__item:last-child {
  margin-bottom: 0;
}

.cl-controls__tooltip {
  position: absolute;
  top: 50%;
  left: 100%;
  display: none;
  transform: translate(15px, -50%) scale(0);
  opacity: 0;
  transition: transform 0.3s ease-in-out 0.1s, opacity 0.3s ease;
  transform-origin: 0 50%;
}

.cl-viewer_zoom-mode .cl-controls__tooltip {
  right: 100%;
  left: auto;
  transform: translate(-15px, -50%) scale(0);
  transform-origin: 100% 50%;
}

.cl-controls__item:hover .cl-controls__tooltip {
  opacity: 1;
  transform: translate(15px, -50%) scale(1);
}

.cl-viewer_zoom-mode .cl-controls__item:hover .cl-controls__tooltip {
  opacity: 1;
  transform: translate(-15px, -50%) scale(1);
}

@media only screen and (min-width: 1360px) {
  .cl-controls__tooltip {
    display: block;
  }
}

/* end .cl-controls */
/* begin .cl-tooltip */
.cl-tooltip {
  padding: 10px 15px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  white-space: nowrap;
  background: #fff;
  border: 1px solid #cacaca;
  box-shadow: 0 6px 15px rgba(46, 45, 45, 0.18);
  border-radius: 6px;
}

.cl-tooltip:before, .cl-tooltip:after {
  content: '';
  position: absolute;
  top: 50%;
  right: 100%;
  width: 0;
  height: 0;
  border-width: 7px 14px 7px 0;
  border-style: solid;
  border-color: transparent #cacaca transparent transparent;
  transform: translate(0, -50%);
}

.cl-tooltip:after {
  border-color: transparent #fff transparent transparent;
  transform: translate(1px, -50%);
}

.cl-viewer_zoom-mode .cl-tooltip:before, .cl-viewer_zoom-mode .cl-tooltip:after {
  right: auto;
  left: 100%;
  border-width: 7px 0 7px 14px;
}

.cl-viewer_zoom-mode .cl-tooltip:before {
  border-color: transparent transparent transparent #cacaca;
}

.cl-viewer_zoom-mode .cl-tooltip:after {
  border-color: transparent transparent transparent #fff;
  transform: translate(-1px, -50%);
}

/* end .cl-tooltip */
/* begin .cl-control */
.cl-control {
  position: relative;
  display: block;
  width: 36px;
  height: 36px;
  margin: 0;
  padding: 0;
  fill: #000;
  opacity: 1;
  background: #cacaca;
  border: none;
  outline: none;
  cursor: pointer;
  transition: all 0.4s ease;
}

.cl-control:disabled {
  opacity: 0.4;
  cursor: default;
}

.cl-control__icon {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 18px;
  height: 18px;
  fill: inherit;
  transform: translate(-50%, -50%);
}

.cl-control:hover {
  opacity: 0.6;
}

.cl-control_state_active {
  fill: #fff;
  background: #151515;
}

/* end .cl-control */
</style>
