<template>
    <form class="cl-search">
        <input type="text" placeholder="Поиск по изданию" class="cl-search__input">
        <button type="submit" class="cl-search__submit">
            <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" class="cl-search__icon">
                <path d="M20.75 19.6895L15.086 14.0255C16.4471 12.3915 17.1258 10.2956 16.981 8.17393C16.8361 6.05223 15.8789 4.06805 14.3084 2.63414C12.7379 1.20023 10.675 0.427009 8.54893 0.475317C6.42284 0.523626 4.39723 1.38975 2.89347 2.89351C1.38971 4.39727 0.523588 6.42288 0.475279 8.54897C0.42697 10.6751 1.2002 12.7379 2.6341 14.3084C4.06801 15.8789 6.05219 16.8362 8.17389 16.981C10.2956 17.1258 12.3914 16.4471 14.0255 15.086L19.6895 20.75L20.75 19.6895ZM1.99996 8.75C1.99996 7.41498 2.39585 6.10994 3.13754 4.9999C3.87924 3.88987 4.93345 3.02471 6.16685 2.51382C7.40025 2.00292 8.75745 1.86925 10.0668 2.1297C11.3762 2.39015 12.5789 3.03303 13.5229 3.97703C14.4669 4.92104 15.1098 6.12377 15.3703 7.43314C15.6307 8.74251 15.497 10.0997 14.9862 11.3331C14.4753 12.5665 13.6101 13.6207 12.5001 14.3624C11.39 15.1041 10.085 15.5 8.74996 15.5C6.96036 15.498 5.24463 14.7862 3.97919 13.5208C2.71375 12.2553 2.00195 10.5396 1.99996 8.75Z" fill="black"/>
                </svg>
        </button>
    </form>
</template>

<script>
export default {
  name: 'SearchForm',
  props: {
    q: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus">
/* begin cl-search */
.cl-search {
    position: relative;
    display: block;
}

.cl-search__input {
    position: relative;
    display: block;
    width: 100%;
    height: 43px;
    padding: 0 0 0 40px;
    color: #000;
    font-size: 16px;
    font-weight: 500;
    line-height: 43px;
    background: transparent;
    border: none;
    border-bottom: 1px solid #bcbcbc;
    outline: none;
}

.cl-search__submit {
    position: absolute;
    top: 10px;
    left: 0;
    display: block;
    width: 20px;
    height: 20px;
    background: transparent;
    border: none;
    outline: none;
}

.cl-search__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: 20px;
    height: 20px;
    transform: translate(-50%, -50%);
}
/* end cl-search */
</style>