<template>
  <div class="cl-grid__inner cl-page">
    <div class="cl-page__text">{{ text }}</div>
  </div>
</template>

<script>
export default {
  name: "Page",
  props: {
    text: String,
  },
  data: function () {
    return {};
  },
  mounted: function () {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus">
/* begin .cl-page */
.cl-page {
  background: #fff;
}

.cl-page__text {
  padding: 20px;
}

/* end .cl-page */
</style>
